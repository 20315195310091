.str-video__call-controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;

  .str-video__reactions-menu {
    background-color: var(--str-video__base-color6);
    display: flex;

    padding: var(--str-video__spacing-sm);
    gap: 0.5rem;

    border-radius: var(--str-video__border-radius-lg);

    &--horizontal {
      flex-direction: row;
    }

    &--vertical {
      flex-direction: column;
    }

    .str-video__reactions-menu__button {
      font-family: 'Twemoji Mozilla', Apple Color Emoji, 'Segoe UI Emoji',
        'Noto Color Emoji', 'EmojiOne Color';
      font-size: 1.2rem;
      height: 38px;
      width: 38px;
      background-color: var(--str-video__button-primary-base);
      border-radius: var(--str-video__border-radius-circle);
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--str-video__button-primary-hover);
        cursor: pointer;
      }
    }
  }
}

.str-video__no-media-permission {
  position: absolute;
  background-color: var(--str-video__alert-caution);
  height: 12px;
  width: 12px;
  border-radius: var(--str-video__border-radius-circle);
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: var(--str-video__font-size-xxs);
  color: var(--str-video__base-color4);
  cursor: pointer;
  font-weight: 600;
  top: 0;
  right: -3px;
}

.str-video__composite-button.str-video__device-unavailable {
  .str-video__composite-button__button-group {
    background-color: var(--str-video__button-default-disabled);

    &:hover {
      background-color: var(--str-video__button-default-hover);
    }
  }
}
